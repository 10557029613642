export default function externalLinks(element: string): void {
    const links: NodeListOf<HTMLAnchorElement> = document.querySelectorAll(element);

    links.forEach((link: HTMLAnchorElement) => {
        link.addEventListener('click', (e: Event) => {
            e.preventDefault();

            const href = link.getAttribute('href');

            if (href) {
                window.open(href);
            }
        });
    });
}
