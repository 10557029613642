const inputFieldTypes = 'input:not([type=hidden]):not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=reset]):not([type=button]), select:not(.frm__select--lg), textarea';

export function checkInputFieldEmpty(): void {
    const elements: NodeListOf<HTMLInputElement> = document.querySelectorAll(inputFieldTypes);
    const events: string[] = ['input', 'change', 'blur'];

    events.forEach((event: string) => {
        document.addEventListener(event, (e: Event) => {
            const target: EventTarget | null = e.target;

            if (target && (target instanceof HTMLInputElement || target instanceof HTMLSelectElement || target instanceof HTMLTextAreaElement)) {
                changeInputClassname(target);
            }
        });
    });

    elements.forEach((element: HTMLInputElement) => {
        setTimeout(() => {
            changeInputClassname(element);
        }, 250);
    });
}

export function checkFormReset(): void {
    const forms: NodeListOf<HTMLFormElement> = document.querySelectorAll('form');

    forms.forEach((form: HTMLFormElement) => {
        form.addEventListener('reset', () => {
            const inputFields: NodeListOf<HTMLInputElement> = form.querySelectorAll(inputFieldTypes);

            inputFields.forEach((inputField: HTMLInputElement) => {
                setTimeout(() => {
                    changeInputClassname(inputField);
                }, 250);
            });
        });
    });
}

function changeInputClassname(element: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement): void {
    const FILLED_CLASS = 'is-filled';

    if (element.value) {
        if (element instanceof HTMLSelectElement && element.value === '0') {
            if (element.options[element.selectedIndex].text === 'Other') {
                return element.classList.add(FILLED_CLASS);
            }

            if (element.id === 'regHowFoundTypeID') {
                return element.classList.remove(FILLED_CLASS);
            }
        }

        return element.classList.add(FILLED_CLASS);
    }

    return element.classList.remove(FILLED_CLASS);
}
