export function popupWindow(element: string): void {
    const elements: NodeListOf<HTMLLinkElement> = document.querySelectorAll(element);

    elements?.forEach((elm: HTMLLinkElement) => {
        elm.addEventListener('click', (e: Event) => {
            e.preventDefault();

            const url: string | null = elm.getAttribute('href');
            const height: string | number = elm.getAttribute('data-height') || 320;
            const width: string | number = elm.getAttribute('data-width') || 200;
            const params = `scrollbars=1, resizable=0`;

            if (url) {
                const opener = window.open(url, 'InfoWindow', params);
                opener?.resizeTo(Number(width), Number(height));
            }
        });
    });
}

export function closePopupWindow(element: string): void {
    const closePopupElement: HTMLElement | null = document.querySelector(element);

    closePopupElement?.addEventListener('click', (e: Event) => {
        const self = window.self;
        self.opener = window.self;
        self.close();
    });
}
