export function stickySidebar(sidebarSelector: string, stickyClass: string): void {
    const sidebar = document.querySelector<HTMLElement>(sidebarSelector);

    if (!sidebar) {
        return;
    }

    let ticking = false;

    function checkStickyBehavior(): void {
        const viewportHeight = window.innerHeight;
        const scrollY = window.scrollY;
        const sidebarHeight = sidebar?.offsetHeight || 0;

        if (scrollY + viewportHeight > sidebarHeight) {
            sidebar?.classList.add(stickyClass);
        } else {
            sidebar?.classList.remove(stickyClass);
        }
    }

    function onScroll(): void {
        if (!ticking) {
            window.requestAnimationFrame(() => {
                checkStickyBehavior();
                ticking = false;
            });
            ticking = true;
        }
    }

    window.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onScroll);
    checkStickyBehavior(); // Initial check
}
