function prevent(event: Event) {
    event.stopPropagation();
}

function block(event: Event) {
    event.preventDefault();
}

export function blockContextMenu(elements: NodeListOf<HTMLElement> | HTMLElement): void {
    if (elements instanceof NodeList) {
        elements.forEach((item: HTMLElement) => {
            item.addEventListener('contextmenu', block);
        });
    } else {
        elements.addEventListener('contextmenu', block);
    }
}

export function preventContextMenu(elements: NodeListOf<HTMLElement> | HTMLElement): void {
    if (elements instanceof NodeList) {
        elements.forEach((item: HTMLElement) => {
            item.addEventListener('contextmenu', prevent);
        });
    } else {
        elements.addEventListener('contextmenu', prevent);
    }
}
