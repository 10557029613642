import { ANIMATION, IS_ACTIVE } from '../constants';

export default function backToTop(element: string) {
    const elm: HTMLElement | null = document.querySelector(element);

    window.addEventListener('scroll', (e: Event) => {
        const offset: number | undefined = document.querySelector('html')?.scrollTop;

        if (offset && offset > 300) {
            elm?.classList.remove(ANIMATION.FADE_OUT_FAST);
            elm?.classList.add(ANIMATION.FADE_IN_FAST);
            elm?.classList.add(IS_ACTIVE);
        } else {
            elm?.classList.remove(ANIMATION.FADE_IN_FAST);
            elm?.classList.add(ANIMATION.FADE_OUT_FAST);
            elm?.classList.remove(IS_ACTIVE);
        }
    });
}
