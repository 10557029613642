// ping session keep-alive.
import './session';
import replaceState from './helpers/replace-state';

// WebP support
import isWebpSupported from './helpers/is-webp-supported';

// Collapsible list for FAQ/Sitemap
import collapsibleList from './features/collapsible-list';

// Emoticons replacement
import displayEmoticons from './features/display-emoticons';

// Modal class
import Modal from './features/modal';

// Popup Window
import { popupWindow, closePopupWindow } from './features/popup-window';

// External links
import externalLinks from './helpers/external-link';

// Check for empty/filled input fields and apply specific class
import { checkFormReset, checkInputFieldEmpty } from './forms/check-field-empty';

// Scroll to
import { smoothScrollToHash } from './features/scroll-to-hash';

// Tooltip
import dynamicTooltip from './features/tooltip';

// Remove image loader when image is loaded
import removeImageLoaderOnLoad from './helpers/remove-image-loader';
import { preventContextMenu } from './features/prevent-context-menu';

const currentLocation = window.location.href;

// Do what you want
isWebpSupported();
removeImageLoaderOnLoad();
externalLinks('a[rel*="external"]');
popupWindow('.js-popup');
closePopupWindow('.js-popup-close');
displayEmoticons('.has-emoticons');
collapsibleList('.js-collapsible-list');
checkInputFieldEmpty();
checkFormReset();
dynamicTooltip();
preventContextMenu(document.querySelectorAll('.img-ctx-block'));

if (!currentLocation.includes('faq.html')) {
    smoothScrollToHash();
}

const modal = new Modal();

(function () {
    const url = new URL(document.location.href);
    url.searchParams.delete('forceDevice');
    replaceState({}, '', url.toString());
})();
