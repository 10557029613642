import { ToastNotification } from '../typings';
import Toastify from 'toastify-js';

const TOAST_TIMEOUT = 3000;

export default class Toast {
    constructor() {}

    public static show(parameters: ToastNotification) {
        const { message, hideAfter, type, close, position, gravity }: ToastNotification = { ...parameters };

        Toastify({
            text: `<p>${message}</p>`,
            className: type ? type : 'info',
            duration: hideAfter ? hideAfter : TOAST_TIMEOUT,
            close: close ? close : true,
            gravity: gravity ? gravity : ccmn.f.defaultToastDisplayGravity,
            position: position ? position : 'right',
            escapeMarkup: false,
        }).showToast();
    }

    public static dismissAll() {
        document.querySelectorAll('.toastify').forEach((element: HTMLElement) => {
            element.remove();
        });
    }
}
