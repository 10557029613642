import replaceState from '../helpers/replace-state';
import getURLHash from '../helpers/get-url-hash';
import { GALLERY_PICTURE_PREFIX } from '../constants';

const SCROLL_CORRECTION = 40;
const CLICK_TIMEOUT = 500;

export function smoothScrollToHash(): void {
    const hash: string | null = getURLHash(document.location);
    const currentLocation = window.location.href;

    scrollToElement(hash);
    attachInlineElementEvents(currentLocation);
}

function attachInlineElementEvents(currentLocation: string): void {
    const externalLinkTypes: string[] = ['_blank', 'external'];
    const externalLinks: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('a[href^="#"]:not([href="#"]):not([href="#0"]):not([href*="modal"])');

    externalLinks.forEach((link: HTMLAnchorElement) => {
        link.addEventListener('click', (e: Event) => {
            const target: EventTarget | null = e.target;
            const futureLocation: string | undefined = target?.toString().split('#')[0];

            if (!(target instanceof HTMLAnchorElement)) {
                return;
            }

            e.preventDefault();

            const elmTarget: string | null = target.getAttribute('target');
            const elmRel: string | null = target.getAttribute('rel');

            if (currentLocation !== futureLocation) {
                if ((elmTarget && externalLinkTypes.includes(elmTarget)) || (elmRel && externalLinkTypes.includes(elmRel))) {
                    return;
                } else {
                    return (location.href = target.href);
                }
            }

            return scrollToElement(getURLHash(target.href));
        });
    });
}

export function scrollToElement(hash: string | null): void {
    if (hash) {
        const decodedHash: string = decodeURI(hash);
        const elementByID: HTMLAnchorElement | null = document.querySelector(decodedHash);
        const elementByName: HTMLAnchorElement | null = document.querySelector(`[name="${decodedHash.split('#')[1]}"]`);
        const target: HTMLAnchorElement | null = elementByID ? elementByID : elementByName;
        const offset: DOMRect | undefined = target?.getBoundingClientRect();

        if (!hash.includes(GALLERY_PICTURE_PREFIX)) {
            replaceState(null, '', ' ');
        }

        if (offset) {
            try {
                window.scroll({ top: offset.top - SCROLL_CORRECTION });
            } catch {
                window.scrollTo(0, offset.top - SCROLL_CORRECTION);
            }

            setTimeout(() => {
                if (target?.classList.contains('is-collapsible')) {
                    target.click();
                }
            }, CLICK_TIMEOUT);
        }
    }
}
