export default function isWebpSupported(): void {
    if (!checkWebpSupport()) {
        document.documentElement.classList.add('no-webp');
    }
}

function checkWebpSupport() {
    const element = document.createElement('canvas');
    element.setAttribute('crossorigin', 'anonymous');
    if (!!(element.getContext && element.getContext('2d'))) {
        return element.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }

    return false;
}
