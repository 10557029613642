export default function setCookie(name: string, value: any, days?: number, secureCookie: boolean = true, sameSiteCookie: boolean = true): string {
    const domain: string = window.location.host;
    const secure = secureCookie ? '; secure' : '';
    const sameSite = sameSiteCookie ? '; SameSite=strict' : '';

    let expires: string;

    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
    } else {
        expires = '';
    }

    return (document.cookie = `${name}=${value}${expires}; path=/; domain=${domain}${secure}${sameSite}`);
}
