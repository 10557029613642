export default function appendScripts(element: HTMLElement): void {
    const scriptElements: NodeListOf<HTMLScriptElement> = element.querySelectorAll('script');
    const scriptTags: HTMLScriptElement[] = [];

    scriptElements.forEach((script: HTMLScriptElement) => {
        const src: string | null = script.getAttribute('src');
        const integrity: string | null = script.getAttribute('integrity');
        const content: string | null = script.innerHTML;

        const scriptTag: HTMLScriptElement = document.createElement('script');

        scriptTag.async = true;
        scriptTag.defer = true;

        if (integrity) {
            scriptTag.integrity = integrity;
        }

        if (src) {
            scriptTag.src = src;
        }

        if (content) {
            scriptTag.innerHTML = content;
        }

        scriptTags.push(scriptTag);

        script.parentNode?.removeChild(script);
    });

    scriptTags.forEach((scriptTag: HTMLScriptElement) => {
        element.appendChild(scriptTag);
    });
}
