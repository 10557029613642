export default function getURLPart(): string {
    const url = new URL(document.location.href);
    const urlPath: string = url.pathname;
    const urlSearch: string = url.search;
    const urlHash: string = url.hash;
    const urlParams: URLSearchParams = new URLSearchParams(urlSearch);

    let params: string = urlParams.toString();
    params = params ? `?${params}` : '';

    return `${urlPath}${params}${urlHash}`;
}
