import { ANIMATION, IS_ACTIVE, IS_VISIBLE } from '../constants';

export default function collapsibleList(element: string) {
    const collapsibleLists: NodeListOf<HTMLDivElement> = document.querySelectorAll(element);

    collapsibleLists.forEach((list: HTMLDivElement) => {
        list.addEventListener('click', (e: Event) => {
            const target: EventTarget | null = e.target;

            if ((target instanceof HTMLHeadingElement || target instanceof HTMLAnchorElement) && target.classList.contains('is-collapsible')) {
                e.preventDefault();

                const isActive = target.classList.contains(IS_ACTIVE);
                const nextElement: Element | null = target.nextElementSibling;

                target.classList.toggle(IS_ACTIVE);

                if (isActive) {
                    nextElement?.classList.add(ANIMATION.SLIDE_OUT_FAST);
                    nextElement?.classList.remove(ANIMATION.FADE_IN_FAST);
                    nextElement?.classList.remove(IS_VISIBLE);
                } else {
                    nextElement?.classList.remove(ANIMATION.SLIDE_OUT_FAST);
                    nextElement?.classList.add(ANIMATION.FADE_IN_FAST);
                    nextElement?.classList.add(IS_VISIBLE);
                }

                Analytics.sendEvent({
                    category: 'Collapsible list',
                    action: isActive ? 'Closed' : 'Opened',
                    label: target.innerText,
                });
            }
        });
    });
}
