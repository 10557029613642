export const cacheRevision = '?v3.1';
const emoticonsContainer = '.has-emoticons';
const emoticonsPathWebp = '/com/img/v2/emoticons/';
const emoticonsPathLight = '/com/img/v2/emoticons/light/';
const emoticonsPathDark = '/com/img/v2/emoticons/dark/';
const emoticons: (string | RegExp)[][] = [
    ['02', '(banghead)', /\(banghead\)/gi],
    ['03', '($)', /\(\$\)/gi],
    ['04', '(U)', /\(U\)/gi],
    ['05', 'I-)', /I\-\)/gi],
    ['06', '(^)', /\(\^\)/gi],
    ['07', '(CENSORED)', /\(CENSORED\)/gi],
    ['08', ':-/', /\:\-(s|\/)/gi],
    ['11', '(diabolo)', /\(diabolo\)/gi],
    ['12', ':$', /\:\$/gi],
    ['13', '(!)', /\(\!\)/gi],
    ['14', '(fried)', /\(fried\)/gi],
    ['15', '(L)', /\(l\)/gi],
    ['16', '(hi)', /\(hi\)/gi],
    ['17', '(k)', /\(k\)/gi],
    ['18', '(lol)', /(\(lol\)|(:|=)[-]?\)\))/gi],
    ['19', '(loser)', /\(loser\)/gi],
    ['20', '(ok)', /\(ok\)/gi],
    ['21', ':o', /\:o/gi],
    ['22', '(oops)', /\(oops\)/gi],
    ['23', ':-)', /(:|=)([-]?)\)/gi],
    ['01', '(a)', /\(a\)/gi],
    ['10', '(d)', /\(d\)/gi], // cannot get `>:)` to work, as `:-)` always replaces first
    ['09', ':((', /(\:\(\()/gi], // needed to move before `:-(`, so it's replaced correctly
    ['24', ':-(', /((:|=)[-]?\()/gi],
    ['25', '(scared)', /\(scared\)/gi],
    ['26', 'B-)', /b\-\)/gi],
    ['27', '(silence)', /\(silence\)/gi],
    ['28', '(sleeping)', /\(sleeping\)/gi],
    ['29', '(squeezed)', /\(squeezed\)/gi],
    ['30', ':D', /\:d/gi],
    ['31', '(thanks)', /\(thanks\)/gi],
    ['32', ':P', /\:p/gi],
    ['34', ':|', /\:\|/gi],
    ['33', '(uzi)', /\(uzi\)/gi],
    ['36', ';)', /\;\)/gi],
    ['35', '(rose)', /\(rose\)/gi],
    ['37', '(love)', /\(love\)/gi],
];

export default function displayEmoticons(elementClass: string = emoticonsContainer, path: string = emoticonsPathLight) {
    const elements: NodeListOf<HTMLElement> = document.querySelectorAll(elementClass);

    elements.forEach((elm: HTMLElement) => {
        const darkScheme: string | null = elm.getAttribute('data-color');

        if (darkScheme === 'dark') {
            path = emoticonsPathDark;
        }

        let content: string = elm.innerHTML;
        let regs;
        let tag: string;
        let emoticon;
        const emotLen = emoticons.length;

        for (let x = 0; x < emotLen; x++) {
            tag = `@@@@SMILE${x}@@@@`;
            regs = emoticons[x][2];
            content = content.replace(regs, tag);
        }

        for (let x = 0; x < emotLen; x++) {
            emoticon = emoticons[x];
            regs = new RegExp(`@@@@SMILE${x}@@@@`, 'g');
            tag = `<picture class="emoticon"><source srcset="${emoticonsPathWebp}${emoticon[0]}.webp${cacheRevision}" type="image/webp"><source srcset="${path}${emoticon[0]}.gif${cacheRevision}" type="image/gif"><img src="${path}${emoticon[0]}.gif${cacheRevision}" alt="${emoticon[1]}" title="${emoticon[1]}" loading="lazy"></picture>`;
            content = content.replace(regs, tag);
        }

        elm.innerHTML = content;
    });
}
