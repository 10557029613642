import { ElementIsOutProperties } from '../typings';

const calculateTooltipPosition = (element: HTMLElement, parentElement?: HTMLElement): string => {
    let isOut: ElementIsOutProperties;
    const elementPosition: DOMRect = element.getBoundingClientRect();
    const pseudoElementWidth: number = getPseudoElementWidth(element);
    const pseudoElementHeight: number = getPseudoElementHeight(element);
    const isDown: boolean = elementPosition.bottom + pseudoElementHeight > (window.innerHeight || document.documentElement.clientHeight);

    if (parentElement) {
        const parentElementPosition: DOMRect = parentElement.getBoundingClientRect();
        const relativePosition = {
            left: elementPosition.left - parentElementPosition.left,
            right: elementPosition.right - parentElementPosition.right,
        };
        isOut = {
            left: relativePosition.left + elementPosition.width < pseudoElementWidth,
            right: relativePosition.left + pseudoElementWidth > parentElementPosition.width,
        };
    } else {
        isOut = {
            left: elementPosition.left + pseudoElementWidth / 2 < 0,
            right: elementPosition.right + pseudoElementWidth / 2 > (window.innerWidth || document.documentElement.clientWidth),
            top: elementPosition.top + pseudoElementHeight / 2 < 40, // we have fixed header which needs to be taken in mind, so that it doesn't cover tooltip
        };
    }

    if ((isOut.right && isOut.left) || (!isOut.right && !isOut.left)) {
        return isDown ? 'top' : 'down';
    }

    return isOut.right ? 'left' : 'right';
};

const getPseudoElementWidth = (element: HTMLElement): number => {
    const defaultPseudoElementWidth = 130;
    const elementWidth = getNumberFromString(getPseudoElementProperty(element, ':after', 'width'));
    return elementWidth > defaultPseudoElementWidth ? elementWidth : defaultPseudoElementWidth;
};

const getPseudoElementHeight = (element: HTMLElement): number => {
    const defaultPseudoElementHeight = 25;
    const elementHeight = getNumberFromString(getPseudoElementProperty(element, ':after', 'height'));
    return elementHeight > defaultPseudoElementHeight ? elementHeight : defaultPseudoElementHeight;
};

const getPseudoElementProperty = (element: HTMLElement, pseudoElement: string, property: string): string => {
    return window.getComputedStyle(element, pseudoElement).getPropertyValue(property);
};

const getNumberFromString = (element: string): number => {
    return parseInt(element, 10);
};

const showTooltip = (event: MouseEvent) => {
    const element: EventTarget | null = event?.target;
    if (element instanceof HTMLElement && element.dataset?.tooltip) {
        const parentElement: HTMLElement | null = element.closest('.modal--visible');
        const direction: string = parentElement ? calculateTooltipPosition(element, parentElement) : calculateTooltipPosition(element);

        element.setAttribute('data-tooltip-pos', direction);
    }
};

const dynamicTooltip = () => {
    const tolltips: NodeListOf<HTMLElement> = document.querySelectorAll('[data-tooltip]');
    tolltips.forEach((tooltip: HTMLElement) => {
        tooltip.addEventListener('mouseover', showTooltip);
    });
};

export default dynamicTooltip;
