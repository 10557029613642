import setCookie from '../helpers/set-cookie';

export default function switchSiteVersion(element: string): void {
    const switchElement: HTMLElement | null = document.querySelector(element);
    if (switchElement) {
        switchElement.addEventListener('click', (e: Event) => {
            e.preventDefault();
            setCookie('_fev', 1);
            window.location.reload();
        });
    }
}
