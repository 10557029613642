import { URLExtraParams } from '../typings';

export default function appendURLParams(path: string, extraParams?: URLExtraParams): string {
    const origin: string = document.location.origin;
    const url: URL = new URL(`${origin}${path}`);
    const urlPath: string = url.pathname;
    const urlSearch: string = url.search;
    const urlHash: string = url.hash;
    const urlParams: URLSearchParams = new URLSearchParams(urlSearch);

    if (extraParams) {
        for (const [param, value] of Object.entries(extraParams)) {
            urlParams.set(param, value);
        }
    }

    let params: string = urlParams.toString();
    params = params ? `?${params}` : '';

    return `${origin}${urlPath}${params}${urlHash}`;
}
