export default function randomId(prefix?: string): string {
    let randomNumber;

    try {
        const uu32: Uint32Array = new Uint32Array(1);
        randomNumber = crypto.getRandomValues(uu32)[0];
    } catch (e) {
        randomNumber = Math.round(Math.random() * 2147483647);
    }

    const result: string = prefix ? `${prefix}-${randomNumber.toString(16)}` : randomNumber.toString(16);

    return result;
}
