const removeImageLoaderOnLoad = (): void => {
    const imageLoaders = document.querySelectorAll<HTMLImageElement>('.img-loader img');
    imageLoaders.forEach((imageLoader: HTMLImageElement) => {
        if (imageLoader?.complete) {
            const imageWrapper: Element | null = imageLoader.closest('.img-loader');
            imageWrapper?.classList.remove('img-loader');
        } else {
            imageLoader?.addEventListener('load', () => {
                const imageWrapper: Element | null = imageLoader.closest('.img-loader');
                imageWrapper?.classList.remove('img-loader');
            });
        }
    });
};

export default removeImageLoaderOnLoad;
