import './common.common';

// Switch site version
import switchSiteVersion from './features/switch-site-version';

// Back to top crap
import backToTop from './features/back-to-top';

// Sticky sidebar
import { stickySidebar } from './features/sticky-sidebar';

switchSiteVersion('.js-version-switch');
backToTop('.js-back-to-top');

// Add sidebar class and a custom stick class
stickySidebar('.sidebar', 'stick-to-bottom');
