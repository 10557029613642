export default class Ajax {
    private url: string;
    private pagePath: string = window.location.pathname;
    private defaultOptions: Object = { credentials: 'same-origin' };

    constructor() {}

    async fetchURL(url: string): Promise<string> {
        return fetch(url, this.defaultOptions)
            .then((response: Response) => {
                if (response.ok) {
                    return response.text();
                }

                throw new Error(`Network response was ${response.status}`);
            })
            .then((body: string) => {
                Analytics.sendEvent({
                    category: 'Fetch',
                    action: `Fetched - ${this.pagePath}`,
                    label: this.url,
                });

                return body;
            });
    }

    async fetchURLResponse(url: string): Promise<Response> {
        return fetch(url, {
            ...this.defaultOptions,
            headers: { 'CC-Redirect-Assist': 'yes' },
        })
            .then((response: Response) => {
                return this.handleResponse(response);
            })
            .then((response: Response) => {
                Analytics.sendEvent({
                    category: 'Fetch',
                    action: `Fetched - ${this.pagePath}`,
                    label: this.url,
                });

                return response;
            });
    }

    async postFormData(url: string, data: FormData, headers?: {}): Promise<Response> {
        return fetch(url, {
            ...this.defaultOptions,
            method: 'POST',
            redirect: 'follow',
            headers: headers,
            body: data,
        })
            .then((response: Response) => {
                return this.handleResponse(response);
            })
            .then((response: Response) => {
                Analytics.sendEvent({
                    category: 'Fetch',
                    action: `Posted - ${this.pagePath}`,
                    label: this.url,
                });

                return response;
            });
    }

    postFiles(url: string, data: FormData): Promise<Response> {
        return fetch(url, {
            ...this.defaultOptions,
            method: 'POST',
            body: data,
        });
    }

    async fetchImage(url: RequestInfo): Promise<string> {
        return fetch(url, this.defaultOptions)
            .then((response: Response) => {
                if (response.ok) {
                    return response.blob();
                }

                throw new Error(`Network response was ${response.status}`);
            })
            .then((image: Blob) => {
                const body: string = URL.createObjectURL(image);

                Analytics.sendEvent({
                    category: 'Fetch',
                    action: `Fetched - ${this.pagePath}`,
                    label: this.url,
                });

                return body;
            });
    }

    handleResponse(response: Response): Response {
        if (response.ok) {
            return response;
        }

        throw new Error(`Network response was ${response.status}`);
    }
}
