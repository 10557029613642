export default function getURLHash(href: Location | string | null): string | null {
    let origin;
    let url: URL;

    if (typeof href === 'object') {
        origin = href;
        url = new URL(`${origin}`);
    } else {
        origin = document.location.origin;
        url = new URL(`${origin}${href}`);
    }

    const hash: string = url.hash;

    return hash;
}
