import { Translation } from '../typings';

export default function translate(params: Translation): string {
    let translation: string;

    if (Array.isArray(params)) {
        const replaceableValues: string[] = [];
        let z = 0;

        translation = translations[params[0]];

        for (let x = 1; x < params.length; x++) {
            replaceableValues.push(params[x].toString());
        }

        translation = translation.replace(new RegExp('%%', 'gim'), '@@@@@@@@@@\0\0@@@@@@@@@@');

        while (1) {
            z++;

            const updatedTranslation = translation.replace(/%s/i, '%' + z + '\\$s');

            if (updatedTranslation !== translation) {
                translation = updatedTranslation;
            } else {
                break;
            }
        }

        for (let i = 0; i < replaceableValues.length; i++) {
            const re = new RegExp('%' + (i + 1) + '\\\\\\$s', 'gim');
            translation = translation.replace(re, replaceableValues[i]);
        }
    } else {
        translation = translations[params];
    }

    return translation;
}
