export function getScrollbarWidth(): number {
    return window.innerWidth - document.documentElement.clientWidth;
}

export function addScrollbarDifference(): void {
    const scrollbarWidth: number = getScrollbarWidth();
    const scrollbarWidthHalf: number = Math.ceil(scrollbarWidth / 2);
    const header: HTMLDivElement | null = document.querySelector('header.hd');
    const profileBgContainer: HTMLDivElement | null = document.querySelector('.profile__bg');
    const backToTop: HTMLAnchorElement | null = document.querySelector('.js-back-to-top');

    document.documentElement.classList.add('no-scroll');
    document.documentElement.style.borderWidth = `${scrollbarWidth}px`;

    if (header) {
        header.style.width = `calc(100% - ${scrollbarWidth}px)`;
    }

    if (profileBgContainer) {
        profileBgContainer.style.left = `calc(50% - ${scrollbarWidthHalf}px)`;
    }

    if (backToTop) {
        backToTop.style.marginRight = `${scrollbarWidth}px`;
    }
}

export function removeScrollbarDifference(): void {
    const header: HTMLDivElement | null = document.querySelector('header.hd');
    const profileBgContainer: HTMLDivElement | null = document.querySelector('.profile__bg');
    const backToTop: HTMLAnchorElement | null = document.querySelector('.js-back-to-top');

    document.documentElement.classList.remove('no-scroll');
    document.documentElement.style.borderWidth = '';

    if (header) {
        header.style.width = '100%';
    }

    if (profileBgContainer) {
        profileBgContainer.style.left = '50%';
    }

    if (backToTop) {
        backToTop.style.marginRight = '';
    }
}
